#root .container {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  align-items: center;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 3px rgba(51, 46, 58, 0.1));
  background-color: #ffffff;
  padding: 24px;
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
}

#root .container .title {
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 26px;
  color: #3d4852;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  opacity: .4;
}

#root .container .progressContainer {
  margin-bottom: 50px;
}

#root .container .file-drop {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 3px rgba(51, 46, 58, 0.1));
  border: 1px dashed #688ee8;
}

#root .container .file-drop .file-drop-target {
  height: 60vh;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#root .container .file-drop .file-drop-target  .file-drop-dragging-over-target {
  opacity: 0.3;
}

#root .container .file-drop .file-drop-target input[type="file"] {
  width: 0;
  height: 0;
}

#root .container .file-drop .placeholder {
  font-size: 14px;
  letter-spacing: 3px;
  line-height: 26px;
  color: #3d4852;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
}

#root .container .file-drop .placeholder span {
  color: #688ee8;
  cursor: pointer;
}
