.parse-markdown-container {
  margin: 0;
}

.parse-markdown-container .row-container {}

.parse-markdown-container p {
  margin: 0;
}

.parse-markdown-container .bold-text {
  font-weight: bold;
}

.parse-markdown-container .empty-row {
  width: 100%;
  height: 16px;
}

.parse-markdown-container .success {
  color: #41C377;
}

.parse-markdown-container .warning {
  color: #EA7502;
}

.parse-markdown-container .error {
  color: #DC125B;
}

.parse-markdown-container .info {
  color: #8E8E8E;
}
@media print {
  .parse-markdown-container {
    margin: 0;
  }

  .parse-markdown-container .row-container {
    font-size: 12px;
  }

  .parse-markdown-container p {
    margin: 0;
  }

  .parse-markdown-container .bold-text {
    font-weight: bold;
    font-size: 12px;
  }

  .row-container .bold-text {
    margin: 10px 0;
  }

  .parse-markdown-container .empty-row {
    width: 100%;
    height: 16px;
  }

  .parse-markdown-container .success {
    color: #41C377;
  }

  .parse-markdown-container .warning {
    color: #EA7502;
  }

  .parse-markdown-container .error {
    color: #DC125B;
  }

  .parse-markdown-container .info {
    color: #8E8E8E;
  }
}
