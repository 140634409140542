/*Table styles*/
table, tr, td, th {
  border: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.styled_table th, .styled_comparing_table th {
  border-bottom: 1px solid rgba(3, 31, 66, 0.15);
}

th {
  background-color: #ffffff;
  padding: 10px;
}

th:first-child {
  border-bottom: none;
}

.styled_table td, .styled_comparing_table td {
  width: 15%;
  border-left: 1px solid rgba(3, 31, 66, 0.15);
}

td {
  text-align: start;
  padding: 10px 10px 10px 0;
}

.styled_table td, styled_comparing_table td {
  text-align: center;
}

.styled_table td:first-child, .styled_comparing_table td:first-child {
  width: 30%;
}

.styled_comparing_table td {
  text-align: center;
}

.styled_comparing_table td div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
}

.styled_comparing_table td:last-child div {
  justify-content: space-between;
}

.styled_comparing_table td:first-child div {
  justify-content: flex-start;
}

.styled_comparing_table .usual {
  color: #222222;
}

.styled_comparing_table .info {
  color: #0059ba;
}

.styled_comparing_table .success {
  color: #41C377;
}

.styled_comparing_table .warning {
  color: #EA7502;
}

.styled_comparing_table .error {
  color: #DC125B;
}

.styled_comparing_table .worse {
  background-color: rgba(255, 68, 68, 0.15);
  font-weight: bold;
  vertical-align: middle;
  color: rgb(56, 0, 0);
}

.styled_comparing_table .better {
  background-color: rgba(65, 195, 119, .15);
  font-weight: bold;
  vertical-align: middle;
}

.styled_comparing_table .same {
  background-color: rgba(84, 144, 247, 0.15);
  font-weight: bold;
  vertical-align: middle;
  color: rgb(30, 30, 79);
}

td:first-child {
  background-color: #ffffff;
  text-align: start;
  border-left: none;
}

.styled_comparing_table td:nth-child(2), .styled_comparing_table th:nth-child(2) {
  border-left: none;
}

.styled_table td:nth-child(2), .styled_table th:nth-child(2) {
  color: #41C377;
  border-left: none;
}

.styled_table td:nth-child(3), .styled_table th:nth-child(3) {
  color: #EA7502;
}

.styled_table td:nth-child(4), .styled_table th:nth-child(4) {
  color: #DC125B;
}

.styled_table td:nth-child(5), .styled_table th:nth-child(5) {
  color: #000000;
}

.styled_table tr:last-child td:not(:first-child), .styled_comparing_table tr:last-child td:not(:first-child) {
  border-bottom: 1px solid rgba(3, 31, 66, 0.15);
}

.styled_table tr:nth-child(2n+1), .styled_comparing_table tr:nth-child(2n+1) {
  background-color: rgba(3, 31, 66, 0.05);
}

.dot {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  min-width: 30px;
  padding: 5px;
  margin: auto;
  border-radius: 15px;
  background-color: #bbb;
}

.red-dot-bgcolor {
  background-color: rgba(220, 18, 91, .15);
}

.green-dot-bgcolor {
  background-color: rgba(65, 195, 119, .15);
}

.orange-dot-bgcolor {
  background-color: rgba(234, 117, 2, .15);
}

.black-dot-bgcolor {
  background-color: rgba(0, 0, 0, 0.15);
}

.grid-survey-title {
  padding: 20px 0;
  font-weight: 500;
}

.grid-survey-answer {
  padding: 10px;
}

.empty-value {
  color: #8E8E8E !important;
}
