code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body {
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
html {
  scrollbar-transition: smooth;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 18px;
  height: 18px;
  transition: all .25s;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(181, 181, 181, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 4px solid transparent;
  transition: all .25s;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(124, 124, 124, 0.4);
}

::-webkit-scrollbar-thumb {
  background: #ababab;
  border: 4px solid transparent;
  background-clip: padding-box;
  cursor: pointer !important;
  opacity: .4;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  transition: all .25s;
}

::-webkit-scrollbar-thumb:hover:vertical {
  border: 1px solid rgba(124, 124, 124, 0.4);
  box-sizing: border-box;
  opacity: .6;
  transition: opacity .5s ease;
  background: rgb(179, 179, 179);
  background: linear-gradient(90deg, rgba(179,179,179,1), rgba(231,231,231,1), rgba(231,231,231,1), rgba(190,190,190,1));

}

::-webkit-scrollbar-thumb:hover:horizontal {
  border: 1px solid rgb(172, 172, 172);
  box-sizing: border-box;
  transition: opacity .5s ease;
  background: rgb(179, 179, 179);
  background: linear-gradient(rgb(184, 184, 184), rgba(231,231,231,1), rgba(231,231,231,1), rgba(190,190,190,1));
  opacity: .6;
}

::-webkit-scrollbar-track:hover {
  border-color: white;
}

textarea:focus, input:focus {
  outline: none;
}

/*Datepicker Style*/
.custom-react-date-picker {
  width: 100%;
}

.custom-react-date-picker .react-date-picker__wrapper {
  border: none;
}

.custom-react-date-picker .react-date-picker__button {
  opacity: .5;
  padding: 0;
}

.custom-react-date-picker .react-date-picker__inputGroup {
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.custom-react-date-picker .react-date-picker__inputGroup__input {
  letter-spacing: 0 !important;
  padding: 0;
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  padding-left: 0.5em !important;
  margin-left: -0.5em !important;
}

.react-date-picker__inputGroup__divider {
  padding: 0 3px !important;
  line-height: 1.5 !important;
  font-size: 1rem !important;
}

.custom-react-date-picker .react-date-picker__inputGroup__input:invalid {
  background-color: transparent;
}

.custom-react-date-picker .react-date-picker__calendar {
  z-index: 2;
}

.custom-react-date-picker .react-date-picker__inputGroup__input {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0 !important;
}

.custom-react-date-picker .react-date-picker__inputGroup__leadingZero {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0 !important;
}

.react-date-picker__calendar .react-calendar {
  border-radius: 20px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

}

.react-date-picker__calendar .react-calendar__month-view__weekdays {
  font-size: .95rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5rem;
}

.react-date-picker__calendar .react-calendar__tile {
  font-size: .8rem;
}

.react-date-picker__button svg {
  height: 20px;
}

.react-date-picker__calendar .react-calendar__navigation__label__labelText {
  font-size: .95rem;
  color: rgba(0, 0, 0, 0.87);
}

.react-date-picker__calendar .react-calendar__tile {
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.87);
}

.react-date-picker__calendar .react-calendar__navigation {
  height: 50px;
}

.react-date-picker__calendar .react-calendar button {
  border-radius: 25px;
  line-height: 1.5rem;
}

.react-date-picker__calendar .react-calendar__tile--now {
  background: rgba(0, 0, 0, 0.23) !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.react-date-picker__calendar .react-calendar__tile--active {
  background: rgba(0, 168, 255, 0.84);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.react-date-picker, .react-date-picker *, .react-date-picker :after, .react-date-picker :before {
  text-decoration: none;
}

.ReactVirtualized__Table__headerRow {
  /*Rewrite table properties (no props to overwrite styles)*/
  overflow: initial !important;
}


@media print {
  #root {
    display: none;
  }

  #printContent {
    display: block;
  }

  @page {
    size: auto;   /* auto is the initial value */
    margin: 20px 25px 20px 25px; /* margin you want for the content */
  }

  html {
    background-color: #FFFFFF;
    margin: 20px 25px 20px 25px; /* margin you want for the content */
  }

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
