.pagination {
  list-style-type: none;
  display: flex;
  margin: 30px 0 20px 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 20px;
  max-height: 20px;
}

.pagination .page-item .page-link{
  background-color: transparent;
  color: #404040;
  border: gray 1px solid;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 0 5px;
  padding: 0.3rem 1rem;
  cursor: pointer;
}

.pagination .page-item.active .page-link{
  background-color: #0366d6;
  color: white;
  border: #0366d6 1px solid;
}
.pagination .page-item.disabled .page-link{
  background-color: #efeeee;
  color: #9e9e9e;
  border: #9e9e9e 1px solid;
  cursor: default;
}
